<script>
import GalleryImageForm from "@/views/menu/gallery/gallery-image/galleryImageForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    galleryService: "galleryService",
    fileUploadService: "fileUploadService"
  }
})
export default class GalleryImageNew extends mixins(GalleryImageForm) {
  formId = "new-gallery-image-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.galleryService.createGalleryImage(this.model.idGallery, this.model);
      this.$router.push(`/gallery-images/${this.model.idGallery}/list`);
      toastService.success(this.translations.success.galleryImage_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.galleryImages_new;
  }

  afterCreate () {
    this.model = {
      idGallery: this.$route.params.idParent,
      icon: null,
      order: null,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
